import { cn } from '@viastud/ui/lib/utils'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@viastud/ui/table'
import dayjs from 'dayjs'
import { useMemo } from 'react'

type AvailabilityStatus = 'UNAVAILABLE' | 'AVAILABLE' | 'SELECTED'

export interface AvailabilityWithStatus {
  weekStart?: string
  dayOfWeek: number
  hour: number
  slotId: number | null
  status: AvailabilityStatus
}

interface MergedAvailabilityCell {
  rowspan: number
  shouldRender: boolean
  status: AvailabilityStatus
}

interface AvailabilityTableProps {
  weekStart: string
  daysOfWeek: string[]
  hours: number[]
  availabilities: AvailabilityWithStatus[]
  isEditable: boolean
  onSelect: (availability: AvailabilityWithStatus) => void
  defaultStatus: AvailabilityStatus
}

const CELL_HEIGHT = 52
const CELL_WIDTH = 130
const CELL_SPACING = 4

const AvailabilityTable = ({
  weekStart,
  daysOfWeek,
  hours,
  availabilities,
  isEditable,
  onSelect,
  defaultStatus,
}: AvailabilityTableProps) => {
  const availabilityMatrix = useMemo(() => {
    const matrix: AvailabilityStatus[][] = hours.map((hour) =>
      daysOfWeek.map((_, index) =>
        dayjs(weekStart)
          .startOf('week')
          // add + 1 for staging
          .add(index + 1, 'day')
          .add(hour, 'hour')
          .isAfter()
          ? defaultStatus
          : 'UNAVAILABLE'
      )
    )

    availabilities.forEach((availability) => {
      const { dayOfWeek, hour, status } = availability
      const hourIndex = hours.indexOf(hour)
      const dayIndex = dayOfWeek
      if (hourIndex !== -1 && dayIndex >= 0 && dayIndex < daysOfWeek.length) {
        matrix[hourIndex][dayIndex] = status
      }
    })

    return matrix
  }, [availabilities, daysOfWeek, defaultStatus, hours, weekStart])

  const mergedAvailabilities: MergedAvailabilityCell[][] | null = useMemo(() => {
    if (isEditable) {
      return null
    }

    const merged: MergedAvailabilityCell[][] = hours.map(() =>
      daysOfWeek.map(() => ({ rowspan: 1, shouldRender: true, status: 'UNAVAILABLE' }))
    )

    daysOfWeek.forEach((_, dayIndex) => {
      let row = 0
      while (row < hours.length) {
        const currentCell = availabilityMatrix[row][dayIndex]
        let span = 1
        while (
          row + span < hours.length &&
          availabilityMatrix[row + span][dayIndex] === currentCell
        ) {
          span++
        }
        merged[row][dayIndex].rowspan = span
        merged[row][dayIndex].status = currentCell

        for (let i = 1; i < span; i++) {
          if (row + i < hours.length) {
            merged[row + i][dayIndex].shouldRender = false
          }
        }
        row += span
      }
    })

    return merged
  }, [availabilityMatrix, daysOfWeek, hours, isEditable])

  return (
    <div className="overflow-x-auto">
      <Table
        className={cn('min-w-full border-separate rounded-lg shadow-lg', `border-spacing-[4px]`)}
        style={{ tableLayout: 'fixed' }}
      >
        <TableHeader>
          <TableRow>
            <TableHead style={{ width: `${CELL_WIDTH / 2}px` }}>&nbsp;</TableHead>
            {daysOfWeek.map((day, index) => (
              <TableHead
                key={day}
                className="rounded-xl bg-gray-100 px-4 py-2 text-center font-normal text-gray-700"
                style={{ width: `${CELL_WIDTH}px` }}
              >
                {day}
                &nbsp;
                {dayjs(weekStart).add(index, 'days').format('DD/MM')}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {hours.map((hour, hourIndex) => (
            <TableRow
              key={`row-${hour}`}
              className={`h-[${CELL_HEIGHT}px] [&>*:first-child]:rounded-l-md [&>*:last-child]:rounded-r-md`}
            >
              <TableCell className="flex items-start justify-end px-2 pt-0 text-center">
                <div className="flex justify-self-end font-medium text-gray-500">
                  {hour}&nbsp;:&nbsp;00
                </div>
              </TableCell>
              {daysOfWeek.map((dayOfWeek, dayIndex) => {
                if (!isEditable && mergedAvailabilities) {
                  const { rowspan, shouldRender, status } =
                    mergedAvailabilities[hourIndex][dayIndex]
                  if (!shouldRender) {
                    return null
                  }

                  return (
                    <TableCell
                      key={`cell-${dayOfWeek}-${hour}`}
                      rowSpan={rowspan > 1 ? rowspan : undefined}
                      className={cn('rounded-md p-4 transition duration-200 ease-in-out', {
                        'bg-blue-600 text-white': status === 'SELECTED',
                        'bg-blue-50 text-gray-500': status !== 'SELECTED',
                      })}
                      style={{
                        width: `${CELL_WIDTH}px`,
                        height:
                          rowspan > 1
                            ? `${(CELL_HEIGHT + CELL_SPACING) * rowspan - CELL_SPACING}px`
                            : `${CELL_HEIGHT}px`,
                      }}
                    >
                      {status === 'SELECTED' ? (
                        <div className="flex items-center justify-center">
                          <span className="text-sm font-semibold">Disponible</span>
                          <span className="ml-2 text-yellow-300">✓</span>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center">
                          <span className="text-sm font-medium text-blue-600">Indisponible</span>
                        </div>
                      )}
                    </TableCell>
                  )
                }
                const status = availabilityMatrix[hourIndex][dayIndex]
                const isClickable = status !== 'UNAVAILABLE'

                const handleClick = () => {
                  if (isClickable) {
                    const selectedAvailability = {
                      weekStart,
                      dayOfWeek: dayIndex,
                      hour,
                      slotId: null,
                      status: status === 'SELECTED' ? defaultStatus : 'SELECTED',
                    }
                    onSelect(selectedAvailability)
                  }
                }

                return (
                  <TableCell
                    key={`editable-cell-${dayOfWeek}-${hour}`}
                    className={cn(
                      `h-${CELL_HEIGHT}px rounded-md p-4 transition duration-200 ease-in-out`,
                      {
                        'cursor-pointer bg-blue-600 text-white': status === 'SELECTED',
                        'cursor-pointer bg-blue-50 text-blue-600 hover:bg-blue-200':
                          status === 'AVAILABLE',
                        'bg-blue-50 text-gray-500': status === 'UNAVAILABLE',
                        'cursor-default opacity-60': dayjs(weekStart)
                          .startOf('week')
                          //add + 1 for staging
                          .add(dayIndex + 1, 'day')
                          .add(hour, 'hour')
                          .isBefore(),
                      }
                    )}
                    style={{ width: `${CELL_WIDTH}px` }}
                    onClick={handleClick}
                  >
                    {isClickable ? (
                      <div className="flex items-center justify-center">
                        <span className="text-sm font-semibold">
                          {status === 'SELECTED' ? 'Sélectionné' : 'Disponible'}
                        </span>
                        {status === 'SELECTED' && <span className="ml-2 text-yellow-300">✓</span>}
                      </div>
                    ) : (
                      <div className="flex items-center justify-center">
                        <span className="text-sm font-medium">Indisponible</span>
                      </div>
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default AvailabilityTable
