import type { FileDto } from '@viastud/server/services/file_service'
import axios from 'axios'
import type { References } from 'myst-common'
import { DEFAULT_RENDERERS } from 'myst-to-react'
import { VFile } from 'vfile'

import { AdmonitionRenderer, AdmonitionTitle } from '#components/myst/admonitions'
import { ProofRenderer } from '#components/myst/proof'

export const latexToMyst = async (text: string): Promise<string> => {
  const { unified } = await import('unified')
  const { default: mystToMd } = await import('myst-to-md')
  const { TexParser } = await import('tex-to-myst')

  const vfile = new VFile()
  const parser = new TexParser(text, vfile)
  unified().use(mystToMd).stringify(parser.ast, vfile)

  return vfile.result as string
}

export async function replaceImages(content: string, images: FileDto[]) {
  let newContent = content
  for (const image of images) {
    const imageData = await axios.get<Blob>(image.url, {
      responseType: 'blob',
    })

    const fileUrl = URL.createObjectURL(imageData.data)

    newContent = newContent.replace(new RegExp(`(?:imgs/)?${image.name}`, 'i'), fileUrl)
  }
  return newContent
}

export const RENDERERS = {
  ...DEFAULT_RENDERERS,
  admonition: AdmonitionRenderer,
  admonitionTitle: AdmonitionTitle,
  proof: ProofRenderer,
}

export async function parse(text: Promise<string>) {
  const { unified } = await import('unified')
  const {
    basicTransformationsPlugin,
    enumerateTargetsPlugin,
    getFrontmatter,
    keysPlugin,
    mathPlugin,
    reconstructHtmlPlugin,
    ReferenceState,
    resolveReferencesPlugin,
  } = await import('myst-transforms')
  const { proofDirective } = await import('myst-ext-proof')
  const { mystParse } = await import('myst-parser')

  const vfile = new VFile()
  const parseMyst = (content: string) =>
    mystParse(content, {
      markdownit: { linkify: true },
      directives: [proofDirective],
      vfile,
    })
  const mdast = parseMyst(await text)
  const references = {
    cite: { order: [], data: {} },
    footnotes: {},
  }
  const frontmatter = getFrontmatter(vfile, mdast, {
    keepTitleNode: true,
  }).frontmatter

  const state = new ReferenceState('', {
    previousCounts: frontmatter.previousCounts,
    vfile,
  })

  unified()
    .use(reconstructHtmlPlugin)
    .use(basicTransformationsPlugin, { parser: parseMyst })
    .use(mathPlugin, { macros: frontmatter.math })
    .use(enumerateTargetsPlugin, { state })
    .use(resolveReferencesPlugin, { state })
    .use(keysPlugin)
    .runSync(mdast, vfile)

  return {
    frontmatter,
    references: { ...references, article: mdast } as References,
    warnings: vfile.messages,
  }
}
