import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import type * as React from 'react'

import { cn } from '#lib/utils'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-neutral-950 disabled:pointer-events-none disabled:opacity-50 dark:focus-visible:ring-neutral-300',
  {
    variants: {
      size: {
        default: 'h-9 px-4 py-2',
        sm: 'h-8 rounded-md px-3 text-xs',
        lg: 'h-10 rounded-md px-8',
        icon: 'p-2',
        none: 'p-0',
      },
      variant: {
        default:
          'rounded-3xl border-blue-300 bg-gradient-to-b from-[#3347FF] to-[#3B01E0] text-neutral-50 shadow hover:bg-gradient-to-b hover:from-[#2F3FE8] hover:to-[#2A4DFE] dark:border-neutral-800 dark:text-neutral-50 dark:hover:from-[#1D2CB6] dark:hover:to-[#1A27B0]',
        destructive:
          'rounded-3xl bg-red-500 text-neutral-50 shadow-custom hover:bg-red-500/90 dark:bg-red-900 dark:text-neutral-50 dark:hover:bg-red-900/90',
        outline:
          'rounded-3xl border border-blue-300 bg-white text-blue-600 shadow-custom hover:bg-gray-50 hover:text-blue-700 dark:border-neutral-800 dark:bg-neutral-950 dark:hover:bg-neutral-800 dark:hover:text-neutral-50',
        outlineDestructive:
          'rounded-3xl border border-red-500 bg-white text-red-600 shadow-custom hover:bg-gray-50 hover:text-red-700 dark:border-neutral-800 dark:bg-neutral-950 dark:hover:bg-neutral-800 dark:hover:text-neutral-50',
        secondary:
          'rounded-3xl bg-neutral-100 text-neutral-900 shadow-custom hover:bg-neutral-100/80 dark:bg-neutral-800 dark:text-neutral-50 dark:hover:bg-neutral-800/80',
        tableHeaderVariant: 'rounded-3xl hover:bg-[#EFF8FF]',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        none: 'bg-transparent text-neutral-900 hover:bg-transparent hover:text-neutral-900 dark:text-neutral-50 dark:hover:bg-transparent dark:hover:text-neutral-50',
        icon: 'size-auto rounded-full bg-white',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

export interface ButtonProps
  extends React.ComponentProps<'button'>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = ({ ref, className, variant, size, asChild = false, ...props }: ButtonProps) => {
  const Comp = asChild ? Slot : 'button'
  return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
}
Button.displayName = 'Button'

export { Button }
